.App{
    background-image: url('../src/assets/images/dashboard_bg.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    scrollbar-width: thin;          
    scrollbar-color: grey transparent;  
    /* zoom: 100%; */
  }

/* A4 Landscape*/
@page {
  size: A4 landscape;
  /* margin: 10%; */
  /* scale: 40%;*/
  /* transform: scale(0.4);transform-origin: 0 0;  */
}

/* @media print
{
    body
    {
        transform: scale(0.4);transform-origin: 0 0;
    }
} */

/* @media print
{
    html
    {
        zoom: 20%;
        size: 297mm 210mm;
    }
} */

/* width */
::-webkit-scrollbar {
    width: 7px;
    scrollbar-width: 7px;
  } 
  
  /* Track */
 ::-webkit-scrollbar-track {
    background: transparent;
  } 
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    scrollbar-color: #888;
    border-radius: 7px;
  } 
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover { 
    background: #555;
    scrollbar-color: #555;
    border-radius: 7px;
  }

  