
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  /* max-width: 1920px; */
  font-family: "Inter", sans-serif;
}

@layer components {
  .title-md {
    @apply text-xl font-bold;
  }
  .title-lg{
    @apply font-bold text-2xl
  }
  .hover-style{
    @apply hover:bg-blue-200 transition-colors duration-200 cursor-pointer
  }
}
